import CoolLightBox from 'vue-cool-lightbox'
import { mapState } from 'vuex'
import { uploader } from '../../mixins/uploader'

import closeIcon from '../../assets/images/icon_close.svg'
import fileIcon from '../../assets/images/icon_file.svg'
import fileIconGreen from '../../assets/images/icon_file_green.svg'

export default {
  name: 'FileUpload',
  mixins: [uploader],

  props: {
    code: {
      type: String,
      required: true,
    },
    preloadedFiles: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
    },
    prefix: {
      type: String,
      default: '',
    },
  },

  components: { CoolLightBox },

  data() {
    return {
      closeIcon,
      fileIcon,
      slideUpdate: 0,
      slideIndex: null,
      toggleSlider: false,
      folderPrefix: this.prefix,
      tooltipActive: false,
    }
  },

  computed: {
    ...mapState(['loading']),

    attachIcon() {
      return this.theme === 'eco' ? fileIconGreen : fileIcon
    },
  },
}
