export default {
  name: 's-termometer',
  props: ['range', 'id', 'initialValue'],

  data() {
    return {
      inputRange: this.initialValue || 0,
    }
  },

  watch: {
    inputRange() {
      this.$emit('range-change', this.inputRange)
    },
  },
}
