import FileUpload from '../../../../../components/FileUpload'
import { dayTime } from '../../constants'
import SThermometer from '../../../../../components/ui/s-thermometer'
import Metrics from '../../../../../plugins/metrics'

export default {
  name: 'StepFour',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  components: {
    SThermometer,
    FileUpload,
  },

  data() {
    return {
      dayTime,
    }
  },

  mounted() {
    const { source, channel, anonymous } = this.report
    const payloadMetrics = {
      source,
      channel,
      anonymous,
    }

    Metrics.track('Report Step Four Started', payloadMetrics)
  },

  methods: {
    setSafety(safe) {
      this.report.safety = safe
    },

    attach(key) {
      this.report.files.push(key)
    },

    detach(key) {
      const item = this.report.files.indexOf(key)
      if (item > -1) this.report.files.splice(item, 1)
    },
  },
}
